import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import { useUpdateUserBiscuitsMutation } from '../../redux/api/userApi';

// ----------------------------------------------------------------------

const FormSchema = Yup.object().shape({
  biscuits: Yup.number().min(0).required('Biscuits are required'),
});

// ----------------------------------------------------------------------

BiscuitForm.propTypes = {
  id: PropTypes.string,
  biscuits: PropTypes.number,
  onCancel: PropTypes.func,
};

export default function BiscuitForm({ id, biscuits, onCancel }) {
  const [updateUserBiscuits] = useUpdateUserBiscuitsMutation();

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: { biscuits },
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    try {
      await updateUserBiscuits({ biscuits: values.biscuits, id }).unwrap();

      onCancel();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1} sx={{ p: 3 }}>
        <RHFTextField name="biscuits" label="Biscuits" />
      </Stack>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancelar
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting} loadingIndicator="Cargando...">
          {'Actualizar'}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
