import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['UserTag'],
  endpoints: (builder) => ({
    getAuthUser: builder.query({
      query: () => `auth/user`,
      providesTags: ['UserTag'],
    }),

    getUsers: builder.query({
      query: () => `user`,
      providesTags: ['UserTag'],
    }),
    getSubjectUsers: builder.query({
      query: (subjectId) => `subject/users?subjectId=${subjectId}`,
      providesTags: ['UserTag'],
    }),
    updateMe: builder.mutation({
      query: (body) => ({
        url: `user/me`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['UserTag'],
    }),
    addUser: builder.mutation({
      query: (body) => ({
        url: `user`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserTag'],
    }),

    updateUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `user/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['UserTag'],
    }),
    updateUserPassword: builder.mutation({
      query: (body) => ({
        url: `auth/change-password`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserTag'],
    }),
    updateUserBiscuits: builder.mutation({
      query: (body) => ({
        url: `user/biscuits`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['UserTag'],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserTag'],
    }),
  }),
});

export const {
  useGetAuthUserQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
  useAddUserMutation,
  useDeleteUserMutation,
  useLazyGetUsersQuery,
  useUpdateMeMutation,
  useGetSubjectUsersQuery, useUpdateUserBiscuitsMutation
} = userApi;
